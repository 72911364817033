@import './utility/index.scss';

.TopSection {
	&_clientInfo {
		color: $color-grey-light !important;
		font-family: $font-quicksand-sanseriff;
		font-size: 2.8rem;
	}

	&_breadcrums {
		text-transform: uppercase;
	}

	&_buttonsConatiner > * {
		@media only screen and (max-width: 768px) {
			margin-top: 1.6rem;
		}
	}

	&_buttonsConatiner > * + * {
		margin-left: 10px;
	}

	&_specialButtonLeft {
		background: white;
		border: 1px solid $color-grey-orange;
		box-sizing: border-box;
		border-radius: 48px;
		color: $color-grey-orange;

		&:hover {
			background: white;
			border: 1px solid $color-grey-orange;
			color: $color-grey-orange;
		}
	}

	&_specialButtonRight {
		background: white;
		border: 1px solid $color-pink-dark;
		box-sizing: border-box;
		border-radius: 48px;
		color: $color-pink-dark;

		&:hover {
			background: white;
			border: 1px solid $color-pink-dark;
			color: $color-pink-dark;
		}
	}
}
