.authLayout {
	min-height: 100vh;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center top !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f2f2f2;
	// background-image: url('../../public/images/newImages/Background.png') !important;
}
