@import './utility/index.scss';

.dashboard {
	background-color: $color-off-white;

	&_mobilenav {
		@media only screen and (min-width: 767.5px) {
			display: none;
		}

		&_body {
			padding: 0;
		}
	}

	&_topnav {
		position: fixed;
		z-index: 1;
		width: 100%;
		background-color: $color-white;
		height: 7rem;
		-webkit-box-shadow: 0 5px 25px rgba($color-grey, 0.0625);
		-moz-box-shadow: 0 5px 25px rgba($color-grey, 0.0625);
		box-shadow: 0 5px 25px rgba($color-grey, 0.0625);
		padding-top: 0.7rem;

		@media (max-width: $breakpoint-mobile) {
			padding: 0;
		}

		&_logo {
			height: 4.3rem;

			@media (max-width: $breakpoint-mobile) {
				height: 3.6rem;
			}
		}

		&_menu {
			@media only screen and (max-width: 767.5px) {
				display: none;
			}

			border-bottom: none !important;
			display: flex;
			justify-content: end;
		}

		&_avatar {
			position: relative;

			img {
				position: absolute;
				bottom: 0.5rem;
			}

			&_name {
				padding-left: 0.5rem;
			}
		}

		&_notification {
			p {
				color: $color-white;
			}
		}
	}

	&_body {
		background-color: $color-off-white;
		margin-top: 7rem;
		min-height: 92.8vh;
		background-image: url('../../public/images/newImages/Background.png');
		background-size: cover;
		background-repeat: no-repeat;

		&_sidenav {
			@media only screen and (max-width: 720px) {
				display: none;
			}

			@media only screen and (min-width: 721px) and (max-width: 1024px) {
				width: 20.5rem;
			}

			width: 26.5rem;
			background-color: $color-white;
			-webkit-box-shadow: 5px 0 25px rgba($color-grey, 0.0625);
			-moz-box-shadow: 5px 0 25px rgba($color-grey, 0.0625);
			box-shadow: 5px 0 25px rgba($color-grey, 0.0625);

			&_menu {
				border-right: none !important;
				width: 26.5rem;

				@media only screen and (min-width: 721px) and (max-width: 1024px) {
					width: 20.5rem;
				}
			}
		}

		&_content {
			display: flex;
			align-items: center;
			justify-content: center;
			// padding: 3rem 5rem;

			&_card {
				-webkit-box-shadow: 0 5px 100px rgba($color-grey, 0.125);
				-moz-box-shadow: 0 5px 100px rgba($color-grey, 0.125);
				box-shadow: 0 5px 100px rgba($color-grey, 0.125);
				border-radius: 1.5rem !important;
				background: $color-white;
				padding: 5%;
				text-align: center;

				@media only screen and (min-width: 720px) {
					min-width: 75rem;
				}
			}
		}

		&_offercard {
			border-radius: 1.5rem !important;
			margin: 0.5rem;
		}
	}
}

.dashLayout {
	// height: 100vh;
	@media only screen and (max-width: 1024px) {
		height: auto;
	}

	&_sidenaveContainer {
		background-color: $color-white;
		width: 26.5rem !important;

		@media only screen and (max-width: 1024px) {
			width: 20.5rem !important;
		}

		@media only screen and (max-width: 768px) {
			display: none;
		}
	}

	&_headback {
		background-color: $color-white;
		padding: 0 16px !important;
	}
}

//stepper
.Stepper {
	&_card {
		overflow: auto;
	}

	&_steps {
		font-weight: 400;
		font-style: normal;
	}
}
