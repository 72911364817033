@import './utility/index.scss';

.card {
	width: 800px;
	border-radius: 0 16px 16px 0;
	min-height: 50vh;

	@media (max-width: $breakpoint-tablet) {
		width: 450px;
	}

	@media (max-width: $breakpoint-mobile) {
		width: auto;
		border-radius: 0%;
	}
}

.card::-webkit-scrollbar {
	width: 0.1em;
}

.card::-webkit-scrollbar-thumb {
	background-color: darkgrey;
}

.card2 {
	width: 800px;
	border-radius: 16px 16px;
	min-height: 50vh;

	@media (max-width: $breakpoint-tablet) {
		width: 450px;
	}

	@media (max-width: $breakpoint-mobile) {
		width: auto;
		border-radius: 0%;
	}
}

.card2::-webkit-scrollbar {
	width: 0.1em;
}

.card2::-webkit-scrollbar-thumb {
	background-color: darkgrey;
}

.cardStepper {
	border-radius: 16px 0 0 16px;

	@media (max-width: $breakpoint-mobile) {
		border-radius: 0%;
	}
}

.collapse {
	width: 100%;
	background: none;
	border: none;
}

.collapseHeader {
	background: none;
	border: none;
	font-size: 1.6rem;
	line-height: 2.4rem;
	vertical-align: 0;
	color: $color-accordian-black;
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	opacity: 0.66;
	padding: 0.8rem 0;
}

.customParagraph {
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	font-weight: 400;
	line-height: 2.4rem;
	color: $color-grey;
	opacity: 0.66;
	font-size: 1.4rem !important;
	margin: 0;
}

.textHeader {
	color: $color-new-black;
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	// font-weight: bold;
	font-weight: 500;
	line-height: 2.4rem;
}

.columnsStyle {
	display: flex;
	align-items: center;
}

.platformHeaders {
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 2.2rem;
	color: $color-new-black;
}

.spanText {
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 2.4rem;
	color: $color-grey;
	opacity: 0.66;
	padding-top: 30px;
}

//financials css

.LabelText {
	h4 {
		font-family: $rebranding-font-space-grotesk-sanseriff;
	}
}

.newfinancialparagraph {
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	font-style: normal;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: $color-grey;
	opacity: 0.66;
}

.newkycparagraph {
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	font-style: normal;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: $color-grey;
}

.uploadForm {
	width: 100%;
	margin-top: 16px;
}

//connection-done
.thankyouCard {
	width: 588px;
	border-radius: 16px 16px 16px 16px;
	padding: 64px;

	@media (max-width: $breakpoint-mobile) {
		width: auto;
		border-radius: 0%;
	}
}

.thankyoucardTitle {
	// font-family: $font-quicksand-sanseriff;
	font-family: $rebranding-font-archivo-sanseriff;
	font-style: normal;
	font-weight: normal;
	font-size: 2.8rem;
	line-height: 3.5rem;
	text-align: center;
	// color: $color-grey;
	color: $rebranding-color-primary-dark;
}

.thankyouParagraph {
	font-size: 1.6rem;
	color: $color-grey;
	// font-family: $font-oxygen-sanseriff;
	font-family: $rebranding-font-space-grotesk-sanseriff;
	font-style: normal;
	font-weight: normal;
	line-height: 2.4rem;
	// color: $rebranding-color-black;
	// color: $color-grey;
}

// stepper
.stepper {
	display: flex;
	flex-wrap: wrap;
	height: 550px;
	max-height: 550px;
	font-family: $rebranding-font-space-grotesk-sanseriff;

	@media (max-width: $breakpoint-mobile) {
		height: auto;
	}
}
