@import './utility/index.scss';

.tableInputs {
	width: 384px;

	@media only screen and (max-width: 1024px) {
		width: 100%;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 768px) {
		width: 100%;
		margin-bottom: 10px;
	}
}

//client info repayments
.inputContainer > * + * {
	margin-left: 10px;

	@media only screen and (max-width: 1024px) {
		margin-left: 0;
	}

	@media only screen and (max-width: 768px) {
		margin-left: 0;
	}
}

.repamentsButtonContainer {
	margin: 1rem 0 0 0;
}

.repamentsButtonContainer > * + * {
	margin-left: 10px;
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;
	}
}

.sourceButton {
	// background: $color-light-green-white;
	// border: 1px solid #5193ff;
	box-sizing: border-box;
	border-radius: 4px;
	// color: #5193ff;
	// width: 60px;
	// height: 22px;
	padding: 4px 8px;
	text-align: center;
	margin: 0 0 8px 0;
}

.statusButton {
	color: $color-dark-yellow;
	background: $color-light-green-white;
	border: 1px solid $color-dark-yellow;
	box-sizing: border-box;
	border-radius: 4px;
	// width: 60px;
	// height: 22px;
	padding: 4px 8px;
	text-align: center;
	margin: 8px 0;
}

.ActiveButton {
	background: $color-vanilla;
	border: 1px solid $color-ligh-green;
	border-radius: 4px;
	color: $color-ligh-green;
	text-align: center;

	&:hover {
		color: $color-ligh-green;
		background: $color-vanilla;
		border: 1px solid $color-ligh-green;
	}
}

.transactionDetailsformContent {
	color: $color-grey;
	line-height: 170%;
}

.infoContainer_table {
	overflow: auto;
}
