@import './utility/index.scss';

.stepHeaderParagraph {
	// font-family: $font-oxygen-sanseriff;
	color: $color-grey;
	font-style: normal;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 2.4rem;
	text-align: start;
}

//choose offer

.offerName {
	// font-family: $font-quicksand-sanseriff;
	font-family: $rebranding-font-archivo-sanseriff;
	font-style: normal;
	font-weight: 500;
	font-size: 2.1rem;
	line-height: 2.6rem;
	text-align: center;
	margin: 0;
	color: $color-new-black;
}

.offerCards {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 256px;
	background: $color-white;
	border-radius: 16px;
	margin: 0.8rem 0.8rem;
}

.offerBodyDescription {
	// font-family: $font-oxygen-sanseriff;
	color: $color-grey;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 2.4rem;
	text-align: center;
	word-wrap: break-word;
}

.eligibilityheader {
	// font-family: $font-oxygen-sanseriff;
	color: $color-grey;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 2.2rem;
	text-align: center;
	margin: 0;
}

.eligibilityContent {
	// font-family: $font-oxygen-sanseriff;
	color: $color-grey;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 2.2rem;
	margin: 0;
}

.downloadDiv {
	font-size: 1.6rem;
	font-weight: 400;
	margin: 1rem 0;
}

.marge {
	margin: 10px 0;
}
