@import './utility/index.scss';

.navbar {
	&_container {
		background-color: white;
		box-shadow: 0 2.5px 5px rgba(14, 14, 14, 0.0625);
	}

	&_menu {
		width: 26.5rem !important;

		@media only screen and (max-width: 1024px) {
			width: 20.5rem !important;
		}
	}

	&_sideNav {
		@media only screen and (max-width: 768px) {
			display: none;
		}
	}

	&_topNav {
		@media only screen and (max-width: 768px) {
			display: none;
		}
	}

	&_drawerStyle {
		display: none;

		@media only screen and (max-width: 768px) {
			display: block;
		}
	}

	&_logOut {
		@media only screen and (max-width: 768px) {
			display: none;
		}
	}

	&_heroImage {
		@media only screen and (max-width: 375px) {
			width: 200px;
		}
	}

	&_r2s2 {
		color: $color-grey;
	}
}
